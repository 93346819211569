import {Link} from "gatsby";
import {Button, useTheme} from "@material-ui/core";
import * as React from "react";

const OpenConfigurator = () => {
  const theme = useTheme();

  return (
    <div style={{ textAlign: "center", padding: theme.spacing(3),  }}>
      <Link to={"/configure"}>
        <Button variant={"contained"} color={"secondary"} style={{ textDecoration: "none" }}>Rozpocznij zamówienie</Button>
      </Link>
    </div>
  )
}

export default OpenConfigurator;